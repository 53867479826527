// import { usePayPalScriptReducer } from "@paypal/react-paypal-js";
import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import heart from "../../assets/images/shapes/Green-ball.png";
import { Link } from "@gatsbyjs/reach-router";

const AfterSchool = ({ showbutton, top }) => {
  return (
    <section className={`${top && "pt-120"} pb-120`}>
      <Container>
        <Row className="pb-40">
          <Col lg={10}>
            <div className="donate-options__content">
              <div className="block-title">
                <p>
                  <img src={heart} width="15" alt="" />
                  Services
                </p>
                <h3>Other Services</h3>
              </div>
              <p>
                Made You Love Tennis offers services for youth of all ages,
                abilities, and disabilities, including adaptive – our committed
                staff and volunteers service beginners and advanced players. For
                more information, email us at contact@tennisatl.org
              </p>
            </div>
          </Col>
        </Row>
        {showbutton && (
          <Link className="thm-btn dynamic-radius" to="/services">
            Learn More
          </Link>
        )}
      </Container>
    </section>
  );
};

export default AfterSchool;
